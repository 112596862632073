<template>
    <FileUpload @select="onSelect"
        :multiple="false"
        :showUploadButton="false"
        :showCancelButton="false"
        accept="image/png, image/svg+xml"
        class="multiple"
        :class="addition_class"
        :maxFileSize="2000000"
        :disabled="form.is_submit">
        <template #content="{ files, removeFileCallback }">
            <div v-if="files.length">
                <div class="p-fileupload-file p-fileupload-files bg-gray-100" v-if="form.uploading" style="height: 100px">
                    <i class="pi pi-spin pi-spinner mr-2"></i>
                    Uploading...
                </div>
                <div class="p-fileupload-file p-fileupload-files" v-else-if="form.url">
                    <div class="p-fileupload-row">
                        <div>
                            <img
                                role="presentation"
                                alt="banner"
                                :src="files[files.length - 1].objectURL"
                                width="50"
                            />
                        </div>
                        <div class="p-fileupload-filename">
                            {{ files[files.length - 1].name }}
                        </div>
                        <div>...</div>
                        <div>
                            <button class="p-button p-component p-button-icon-only" type="button" @click="form.url = ''">
                                <span class="pi pi-times p-button-icon"></span>
                                <span class="p-button-label">&nbsp;</span>
                                <span class="p-ink" role="presentation"></span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="p-fileupload-empty" v-else>
                    <a href="" class="block px-3 py-4" @click.prevent="removeFileCallback; dropZoneClicked()" v-if="!form.url">
                        <p class="text-center text-black roboto-medium mb-2">
                            <i class="pi pi-upload text-16"></i>
                        </p>
                        <p class="text-center text-black roboto-medium mb-1">
                            Drag & drop files here.
                        </p>
                        <p class="text-center text-12 text-black opacity-60">
                            Maximum size: 2MB
                        </p>
                    </a>
                </div>
            </div>
        </template>
        <template #empty>
            <a href="" class="block px-3 py-4" @click.prevent="dropZoneClicked()" v-if="!form.url">
                <p class="text-center text-black roboto-medium mb-2">
                    <i class="pi pi-upload text-16"></i>
                </p>
                <p class="text-center text-black roboto-medium mb-1">
                    Drag & drop files here.
                </p>
                <p class="text-center text-12 text-black opacity-60">
                    Maximum size: 2MB
                </p>
            </a>
            <!-- FILE PREVIEW -->
            <div class="p-fileupload-file p-fileupload-files" v-else>
                <div class="p-fileupload-row">
                    <div>
                        <img
                            role="presentation"
                            alt="image"
                            :src="form.url"
                            width="50"
                        />
                    </div>
                    <div class="p-fileupload-filename">
                        {{ form.url }}
                    </div>
                    <div>...</div>
                    <div>
                        <button class="p-button p-component p-button-icon-only" type="button" @click="removeUploadedFile()">
                            <span class="pi pi-times p-button-icon"></span>
                            <span class="p-button-label">&nbsp;</span>
                            <span class="p-ink" role="presentation"></span>
                        </button>
                    </div>
                </div>
            </div>
        </template>
    </FileUpload>
</template>

<script>
export default {
    name: 'single-upload-file',
    props: ['type', 'addition_class', 'current_file'],
    data() {
        return {
            form: {
                uploading: false,
                name: '',
                file: '',
                url: '',
                type: 'icon',
            },
            file: null,
        }
    },
    mounted() {
        this.form.url = this.current_file
    },
    updated() {
        this.form.url = this.current_file
    },
    methods: {
        onSelect(event) {
            let files = event.files
            let file = files[0] ? files[0] : null

            if (!file) return

            if (file.type !== 'image/png' && file.type !== 'image/svg+xml') {
                this.$toast.add({
                    severity:'error', 
                    summary: 'Error!', 
                    detail: 'File tidak dapat di proses', 
                    life: 5000,
                })
                return
            }

            let self = this
            let reader = new FileReader();
            this.form.name = file.name
            this.file = file
            
            reader.readAsDataURL(file);
            reader.onload = function () {
                let result = reader.result
                self.form.file = result.split(',')[1]
                /** 
                 * FOR DEMO
                 * */ 
                // self.$emit('responseURL', `https://cdn.coreinitiative.id/menu/${file.name}`)

                self.form.uploading = true
                self.$http
                    .post(`${process.env.VUE_APP_API_URL}/file-upload`, self.form)
                    .then(response => {
                        self.form.uploading = false
                        
                        if (response.data.code !== 200) {
                            self.$toast.add({
                                severity:'error', 
                                summary: 'Error!', 
                                detail: response.data.message, 
                                life: 5000,
                            })
                            return
                        }

                        self.form.url = response.data.data.url
                        self.$emit('responseURL', response.data.data.url)
                    })
            };
        },
        dropZoneClicked() {
            var $chooseButton = document.getElementsByClassName(`p-button p-component p-fileupload-choose${this.addition_class ? ` ${this.addition_class}`: ''}`);
            $chooseButton[0].click();
        },
        removeUploadedFile() {
            this.form.url = ''
            this.$parent.form.banner = ''
            this.$parent.form.icon_url = ''

            if (this.addition_class && this.addition_class === 'primary') {
                this.$parent.form.logo_primary = ''
            }
            if (this.addition_class && this.addition_class === 'secondary') {
                this.$parent.form.logo_secondary = ''
            }
        },
    },
}
</script>

<style lang="scss" scoped>

</style>