<template>
    <div class="px-4 text-14 pb-5">
        <form class="p-fluid pt-2" @submit.prevent="">
            <div class="field single-upload">
                <label class="text-black text-14 roboto-medium">
                    Icon *
                </label>
                <single-upload-file 
                    @responseURL="setFileUrl" 
                    :addition_class="'HELOO'"
                    :current_file="form.icon_url">
                </single-upload-file>
                <label class="mt-2 text-12 text-black opacity-60">
                    Format file: PNG atau SVG
                </label>
            </div>
            <div class="field mb-4">
                <label class="text-black text-14 roboto-medium">
                    Kode Produk *
                </label>
                <InputText type="text" placeholder="Kode Produk" v-model="form.code" :disabled="form.is_submit" />
            </div>
            <div class="field mb-4">
                <label class="text-black text-14 roboto-medium">
                    Nama Produk *
                </label>
                <InputText type="text" placeholder="Nama Produk" v-model="form.name" :disabled="form.is_submit" />
            </div>
            <div class="field mb-4">
                <label class="text-black text-14 roboto-medium">
                    Deskripsi *
                </label>
                <Textarea rows="5" placeholder="Deskripsi" v-model="form.description" :disabled="form.is_submit" />
            </div>
            <div class="field mb-4">
                <label class="text-black text-14 roboto-medium">
                    Servis *
                </label>
                <Dropdown v-model="form.selected_service" :options="services" optionLabel="name" placeholder="Pilih Servis" :disabled="form.is_submit" />
            </div>
            <div class="field">
                <label class="text-black text-14 roboto-medium mb-3">
                    Status
                </label>
                <div class="flex align-items-center">
                    <InputSwitch v-model="form.status_toggle" :disabled="form.is_submit" />
                    <label class="text-black opacity-60 ml-2">
                        Aktif
                    </label>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import SingleUploadFile from '@/components/SingleUploadFile.vue'

export default {
    props: ['data', 'services'],
    components: {
        SingleUploadFile,
    },
    data() {
        return {
            form: {
                id: null,
                icon_url: '',
                code: '',
                name: '',
                description: '',
                selected_service: {},
                service_name: '',
                status_toggle: false,
                status: 0,
                is_submit: false,
            },
        }
    },
    watch: {
        'form': {
            handler: function() {
                this.form.service_name = this.form.selected_service ? this.form.selected_service.name : ''
                this.$emit('updated', this.form)
            },
            deep: true,
        },
    },
    mounted() {
        if (this.data) {
            this.form = {...this.form, ...this.data}
            this.form.selected_service = this.services.find(obj => obj.name == this.form.service_name);
            this.form.status_toggle = this.form.status ? true : false
        }
    },
    methods: {
        setFileUrl(url) {
            this.form.icon_url = url
        },
    },
}
</script>

<style lang="scss" scoped></style>
